<template>
  <div id="app">



     <v-parallax
      dark
      src="@/assets/jumbotron2.jpg" style="height: 100%;"
><v-img

              gradient="to top right, rgba(100,115,201,.9), rgba(0,0,0,.7)"
              height="100vh"
            >
            <Home />
</v-img>
     </v-parallax>
  </div>
</template>
<script>
import Home from "@/views/Home.vue";
export default {
  name: "app",
  components: {
    Home,
  },
};


</script>
<style>
#app {
  background-color: #f3f2f5;
  height: 100%;
}

</style>
