<template>

<div style="height:100%;">
     
     <v-row >
        <v-col
          cols="12"
        >
           <v-img
           lazy-src="@/assets/new-logo.png"
            src="@/assets/new-logo.png"
          ></v-img>
        <span style="color: rgba(255, 255, 255, 0.7); padding-left:20px;" :class="{'bigolescreen-title': $vuetify.breakpoint.lg, 'teenyscreen-title': $vuetify.breakpoint.xs}">Technical Services</span>
      <br/>  <span style="color: rgba(255, 255, 255, 0.7); padding-left:20px;" :class="{'bigolescreen-subtitle': $vuetify.breakpoint.lg, 'teenyscreen-subtitle': $vuetify.breakpoint.xs}">© Indigo Gryphon LLC 2016
<v-btn
                  icon
                  color="purple"
                  href="mailto:info@indigogryphon.com"
                >
                  <v-icon style="color: rgba(255, 255, 255, 0.7); padding-bottom:2px; padding-left:2px;" :class="{'bigolescreen-title': $vuetify.breakpoint.lg, 'teenyscreen-title': $vuetify.breakpoint.xs}" color="purple darken-4">mdi-email-outline</v-icon>
                </v-btn>

        </span>
          
        </v-col>
      </v-row>
      <v-row>
       
        <v-col cols="12"><v-list-item color="rgba(0, 0, 0, .4)" dark>
            <v-list-item-content>
        <v-list-item-subtitle><v-icon size="7">mdi-circle</v-icon>
Testing and Development
        </v-list-item-subtitle>
        <v-list-item-subtitle><v-icon size="7">mdi-circle</v-icon>

   DevOps</v-list-item-subtitle>
        <v-list-item-subtitle><v-icon size="7">mdi-circle</v-icon>
  
Consultation
        </v-list-item-subtitle></v-list-item-content></v-list-item>
            
        </v-col>
      </v-row>

</div>
</template>

<script>

export default {
  name: "home",
  components: {
    
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.ig-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 2rem;
}
.ig-text {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0px !important;
}
.ig-text-contact {
  padding-top: 5px;
  font-size: 1.5rem;
  line-height: 2rem;
}
.v-list-item__subtitle {
  font-size:15px !important;
}
.bigolescreen-title {
  font-size: 4rem !important;
}
.bigolescreen-subtitle {
  font-size: 3rem !important;
}
.teenyscreen-title {
  font-size: 1.5rem !important;
}
.teenyscreen-subtitle {
  font-size: 1rem !important;
}
</style>
